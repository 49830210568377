import React from "react";

// custom typefaces
// import "typeface-lato"


// normalize CSS across browsers
// import "./src/css/normalize.css"

// custom CSS styles
// import "./src/css/style.css"

import { LanguageProvider } from "./src/context/LanguageContext";

import ReactDOM from 'react-dom'

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}

export const wrapRootElement = ({ element }) => (
  <LanguageProvider>{element}</LanguageProvider>
)