exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cas-staideir-js": () => import("./../../../src/pages/cas-staideir.js" /* webpackChunkName: "component---src-pages-cas-staideir-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-nuacht-js": () => import("./../../../src/pages/nuacht.js" /* webpackChunkName: "component---src-pages-nuacht-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/caseStudy.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-frontpage-js": () => import("./../../../src/templates/frontpage.js" /* webpackChunkName: "component---src-templates-frontpage-js" */),
  "component---src-templates-how-we-can-help-js": () => import("./../../../src/templates/howWeCanHelp.js" /* webpackChunkName: "component---src-templates-how-we-can-help-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-social-enterprise-policies-js": () => import("./../../../src/templates/socialEnterprisePolicies.js" /* webpackChunkName: "component---src-templates-social-enterprise-policies-js" */)
}

