import { createTheme } from '@mui/material/styles';

const theme = createTheme({
      typography: {
        fontFamily: [
          'Lato',
          'sans-serif'
        ].join(','),
      heading:{
        fontFamily: "Lato, sans-serif",
        fontSize: "56px",
        fontWeight: 800
      },
      heading2:{
        fontFamily: "Lato, sans-serif",
        fontSize: "36px",
        fontWeight: 700
      },
      heading3:{
        fontFamily: "Lato, sans-serif",
        fontSize: "40px",
        fontWeight: 800
      },
      cardHero:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "28px",
        fontWeight: 400
      },
      subtitle1:{
        fontFamily: 'Lato, sans-serif',
        fontStyle:'italic',
        fontSize: "24px",
        fontWeight: 400
      },
      subtitle2:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "24px",
        fontWeight: 400
      },
      primary3:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "18px",
        fontWeight: 400
      },
      primary2:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "16px",
        fontWeight: 600
      },
      primary:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "18px",
        fontWeight: 700
      },
      bodyheading:{
        color: '#064B6B',
        fontFamily: 'Lato, sans-serif',
        fontSize: "32px",
        fontWeight: 800
      },
      bodyheadingsmall:{
        color: '#064B6B',
        fontFamily: 'Lato, sans-serif',
        fontSize: "28px",
        fontWeight: 800
      },
      bodyheadinglarge:{
        color: '#064B6B',
        fontFamily: 'Lato, sans-serif',
        fontSize: "40px",
        fontWeight: 800
      },
      bodysmall:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "18px",
        fontFeatureSettings: `'pnum' on, 'lnum' on`,
        fontWeight: 400,
      },
      body:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "21px",
        fontFeatureSettings: `'pnum' on, 'lnum' on`,
        fontWeight: 400,
      },
      body2:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "21px",
        fontStyle:"italic",
        fontWeight: 500
      },
      body3:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "30px",
        fontStyle:"italic",
        fontWeight: 500
      },
      body4:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "21px",
        fontFeatureSettings: `'pnum' on, 'lnum' on`,
        fontWeight: 800,
      },
      cardheading:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "24px",
        fontWeight: 800
      },
      cardheading2:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "20px",
        fontWeight: 800
      },
      card:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "18px",
        fontStyle:"italic",
        fontWeight: 500
      },
      card2:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "12px",
        fontWeight: 500
      },
      card3:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "16px",
        fontStyle:"italic",
        fontWeight: 500,
        lineHeight:'22px'
      },
      tag:{
        fontFamily: 'Lato, sans-serif',
        fontSize: "18px",
        fontWeight: 600
      },
    },
    palette:{
      primary:{
        main: '#EB9323'
      },
      secondary:{
        main: '#382157'
      },
      buttonSelected:{
        main: '#84754D'
      },
      button:{
        main:'#F5F2E7'
      },
      button2:{
        main:'#FFE69D'
      },
      buttonSelected2:{
        main:'#FFCE3B'
      }
    },
  });

  export default theme;