import React, {useReducer, createContext} from "react"

const initialState = {
  english: true,
}


if (typeof window !== 'undefined') {
  // Perform localStorage action
  let english = localStorage.getItem('english')
  if(english){
      initialState.english = (english === 'true');
  }
}

const LanguageContext = createContext({
  toggleEnglish: () => {},
  toggleIrish: () => {},
})

function authReducer(state, action){
  switch(action.type){
      case 'TOGGLE_ENGLISH':
          return{
              ...state,
              english: true
          }
      case 'TOGGLE_IRISH':
          return{
              ...state,
              english: false,
          }
      default:
          return state;
  }
}
const LanguageProvider = (props) => {

  const [state, dispatch] = useReducer(authReducer, initialState);

  const toggleEnglish = () => {
  if (typeof window !== 'undefined') {
      // Perform localStorage action
      localStorage.setItem("english", true);
      dispatch( { type:'TOGGLE_ENGLISH' } );
      }
  }

  const toggleIrish = () => {
      if (typeof window !== 'undefined') {
      localStorage.setItem('english', false);
      dispatch( { type:'TOGGLE_IRISH' } );
      }
  }

  return (
      <LanguageContext.Provider value={{ english: state.english, toggleEnglish, toggleIrish }} {...props} />
  )
}

export default LanguageContext;

export {  LanguageProvider };